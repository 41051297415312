<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group
          label="Ürün Kodu"
          label-for="pcode"
        >
          <validation-provider
            #default="{ errors }"
            name="Ürün Kodu"
            rules="required"
          >
            <b-form-input
              id="pcode"
              v-model="dataItem.pcode"
              placeholder="Giriniz"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Başlık"
          label-for="title"
        >
          <validation-provider
            #default="{ errors }"
            name="Başlık"
            rules="required"
          >
            <b-form-input
              id="title"
              v-model="dataItem.title"
              placeholder="Giriniz"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="İngilizce Başlık"
          label-for="en_title"
        >
          <b-form-input
            id="en_title"
            v-model="dataItem.en_title"
            placeholder="Giriniz"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Ürün Grubu"
          label-for="id_product_groups"
        >
          <validation-provider
            #default="{ errors }"
            name="Ürün Grubu"
            rules="required"
          >
            <v-select
              id="id_product_groups"
              v-model="dataItem.id_product_groups"
              :options="groupList"
              label="title"
              :reduce="item => item.id"
              placeholder="Seçiniz"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'ProductAddForm',
  components: {
    vSelect, BFormGroup, BFormInput, ValidationProvider, BRow, BCol,
  },
  computed: {
    dataItem() {
      return this.$store.getters['products/dataItem']
    },
    groupList() {
      return this.$store.getters['productGroups/dataList']
    },
  },
  created() {
    localize('tr')
    this.getGroups()
  },
  methods: {
    getGroups() {
      this.$store.dispatch('productGroups/getDataList')
    },
  },
}
</script>
